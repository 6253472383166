import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from './components/layout/Layout';
import LayoutQuantumMax from './components/layout/LayoutQuantumMax';
import Home from './components/main/Home/Home';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import CableTv from './components/Pages/CableTv/CableTv';
import GetAQoute from './components/Pages/GetAQoute/GetAQoute';
import GetOrderSumary from './components/Pages/GetOrderSumary/GetOrderSumary';
import Internet from './components/Pages/Internet/Internet';
import PhonePg from './components/Pages/PhonePg/PhonePg';
import SpecialOffer from './components/Pages/SpecialOffer/SpecialOffer';
import OptimumInternet from './components/Pages/OptimumInternet/OptimumInternet';
import RefreshOnRouteChange from './RefreshOnRouteChange';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Privacy from './components/main/Common/Privacy/Privacy';
import SearchChamerlean from './components/main/Common/Chamerlean/SearchChamerlean';
import CompanyPage from './components/main/Common/CompanyPages/CompanyPage';
import CmsPage from './components/main/Common/CmsPages/CmsPage';
import LeadCaptureForms from './components/main/Common/Deals/LeadCaptureForms';

// Wireless
import QuantumMaxWireless from './components/main/Common/QuantumMaxWireless/QuantumMax';
import QuantumMaxWirelessHeader from './components/main/Common/QuantumMaxWireless/Header';
import QuantumMaxWirelessFooter from './components/main/Common/QuantumMaxWireless/Footer';

// Cable
import QuantumMaxCable from './components/main/Common/QuantumMaxCable/QuantumMax';
import QuantumMaxCableHeader from './components/main/Common/QuantumMaxCable/Header';
import QuantumMaxCableFooter from './components/main/Common/QuantumMaxCable/Footer';

// Fiber
import QuantumMaxFiber from './components/main/Common/QuantumMaxFiber/QuantumMax';
import QuantumMaxFiberHeader from './components/main/Common/QuantumMaxFiber/Header';
import QuantumMaxFiberFooter from './components/main/Common/QuantumMaxFiber/Footer';

// Deal
import QuantumMaxDeal from './components/main/Common/QuantumMaxDeal/QuantumMax';
import QuantumMaxDealHeader from './components/main/Common/QuantumMaxDeal/Header';
import QuantumMaxDealFooter from './components/main/Common/QuantumMaxDeal/Footer';

// Spectrum
import QuantumMaxSpectrum from './components/main/Common/QuantumMaxSpectrum/QuantumMax';
import QuantumMaxSpectrumHeader from './components/main/Common/QuantumMaxSpectrum/Header';
import QuantumMaxSpectrumFooter from './components/main/Common/QuantumMaxSpectrum/Footer';

import Header from './components/main/Common/Deals/Header';
import Business from './components/main/Common/Business/Business';
import WirelessDeals from './components/main/Common/WirelessDeals/WirelessDeals';
import SecondLeadCaptureForms from './components/main/Common/SecondDeal/LeadCaptureFormsCopy';
import PrepaidPlanForm from './components/main/Common/PrepaidPlan/PrepaidPlanForm';
import PrepaidPlanDeals from './components/main/Common/PrepaidPlanDeals/PrepaidPlanDeals';
// import FiberDeals from './components/main/Common/FiberDeals/FiberDeals.jsx'
import FiberDeals from './components/main/Common/FiberDeals'
import Offers from './components/main/Common/Offers/Offers';
import Frontier from './components/main/Common/Offers/Offers';
import AtT from './components/main/Common/Offers/Offers';
import Wow from './components/main/Common/Offers/Offers';
import Windstream from './components/main/Common/Offers/Offers';

import CableDeals from './components/main/Common/CableDeals/CableDeals';
import Spectrum from './components/main/Common/Spectrum/Spectrum'

import FiberOffer from './components/main/Common/FiberOffer/FiberOffer';
import WindstreamDeals from './components/main/Common/WindstreamDeals/WindstreamDeals';

import ThankYouPage from './components/main/Common/ThankYouPage/ThankYouPage';

function App() {
  return (
    <div className="App">
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/optimum" element={<OptimumInternet />} />
          <Route path="/optimum-offer" element={<SpecialOffer />} />
        </Routes>
      </Router>

      <Router>
        <ToastContainer />
        <RefreshOnRouteChange />
        <Routes>
          {/* Guest routes here */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/roanmoving" element={<Home />} />
            <Route path="/cabletv" element={<CableTv />} />
            <Route path="/search" element={<SearchChamerlean />} />
            <Route path="/internet" element={<Internet />} />
            <Route path="/getaqoute" element={<GetAQoute />} />
            <Route path="/order-sumary" element={<GetOrderSumary />} />
            <Route path="/phone" element={<PhonePg />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            {/* <Route path="/deals" element={<LeadCaptureForms />} /> */}
            {/* <Route path="/all-deals/:zipcode" element={<Deals />} /> */}
            <Route path="/business" element={<Business />} />
            <Route path="/:slug" element={<CompanyPage />} />
            <Route path="/comlink/:slug" element={<CmsPage />} />
            {/* Add your public routes here eg. <Route path="example" element={<Component />} /> */}
          </Route>

          <Route path="/" element={<Layout />}>
            <Route path="/page/:slug" element={<CmsPage />} />
            {/* Add your public routes here eg. <Route path="example" element={<Component />} /> */}
          </Route>

          <Route path="/deals" element={<><Header /><LeadCaptureForms /></>} />
          <Route path="/prepaid-plans" element={<><Header /><PrepaidPlanForm /></>} />
          <Route path="/wirelessdeals-ns" element={<><Header /><SecondLeadCaptureForms /></>} />
          <Route path="/wireless-deals" element={<><Header /><WirelessDeals /></>} />
          <Route path="/prepaidPlans" element={<><Header /><PrepaidPlanDeals /></>} />
          <Route path="/fiberdeals" element={<><Header /><FiberDeals /></>} />
          <Route path="/fiber-deals" element={<><Header /><FiberDeals /></>} />
          <Route path="/offers" element={<><Header /><Offers /></>} />
          <Route path="/frontier" element={<><Header /><Offers /></>} />
          <Route path="/att" element={<><Header /><Offers /></>} />
          <Route path="/wow" element={<><Header /><Offers /></>} />
          <Route path="/windstream" element={<><Header /><Offers /></>} />
          <Route path="/attwireless" element={<><Header /><Offers /></>} />
          <Route path="/cabledeals" element={<><Header /><CableDeals /></>} />
          <Route path="/fiberoffers" element={<><Header /><FiberOffer /></>} />
          <Route path="/fiberplans" element={<><Header /><WindstreamDeals /></>} />
          <Route path="/spectrum" element={<><Header /><Spectrum /></>} />
          <Route path="/thank-you" element={<><Header /><ThankYouPage /></>} />

          {/* For Quantum Max */}
          <Route path="/quantum-max-wireless" element={<><QuantumMaxWirelessHeader /><QuantumMaxWireless /><QuantumMaxWirelessFooter /></>} />
          <Route path="/quantum-max-cabledeals" element={<><QuantumMaxCableHeader /><QuantumMaxCable /><QuantumMaxCableFooter /></>} />
          <Route path="/quantum-max-fiberoffers" element={<><QuantumMaxFiberHeader /><QuantumMaxFiber /><QuantumMaxFiberFooter /></>} />
          <Route path="/quantum-max-deals" element={<><QuantumMaxDealHeader /><QuantumMaxDeal /><QuantumMaxDealFooter /></>} />
          <Route path="/quantum-max-spectrum" element={<><QuantumMaxSpectrumHeader /><QuantumMaxSpectrum /><QuantumMaxSpectrumFooter /></>} />

          {/* <Route path="/deals" element={<><Header /><LeadCaptureForms /></>} /> */}
          <Route path="/optimum" element={<OptimumInternet />} />
          <Route path="/optimum-offer" element={<SpecialOffer />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
