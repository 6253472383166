import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Images } from "../../../../assets/assets";
import "./Header.css";
import Lottie from 'react-lottie-player'
import greenCall from "../../../../assets/json-files/call-green.json";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
const Header = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const location = useLocation();

    const [tfn, setTfn] = useState('8662612148');
    const [formatedtfn, setFormatedTfn] = useState('(866)-261-2148');

    useEffect( () => {
        const fetchedUrl = window.location.href;
        const params = new URL(fetchedUrl).searchParams;
        
        if(params.get('tfn'))
        {
            setTfn(params.get('tfn'));

            setFormatedTfn( "("+params.get('tfn').substring(0, 3)+")-"+params.get('tfn').substring(3, 6)+"-"+params.get('tfn').substring(6) );
        }

    },[])

    return (
        <div>
            <header className="leads-step-header site-header">

                <Container className="custom-container">
                    <Navbar expand="lg">
                        <div className="d-flex justify-content-between align-items-center w-100">
                            {location.pathname == "/roanmoving" ? <img
                                src={Images.quantumMaxLogo}
                                className="img-fluid site-main-logo"
                                alt="img"
                            /> :
                                <Link to="/">
                                    <img
                                        src={Images.quantumMaxLogo}
                                        className="img-fluid site-main-logo"
                                        alt="img"
                                    />
                                </Link>
                            }
                            {/* desktop nav */}
                            <div id="basic-navbar-nav" className="pages-names desktop-nav d-flex align-items-center flex-fill justify-content-end">
                                <div className="haeder-phone d-flex align-items-center">
                                    <a
                                        className="d-flex align-items-center gray-clr pt-0"
                                        href={`tel:+1${tfn}`}
                                    >
                                        <Lottie
                                            className="call-lottie"
                                            loop
                                            animationData={greenCall}
                                            play
                                        />
                                        <span className="mobile-nbr d-flex flex-column ms-xl-3 ms-1">
                                            <small>Get help 24/7</small>
                                            {formatedtfn}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Navbar>
                </Container>
            </header>
        </div>
    );
};

export default Header;
