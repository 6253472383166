import React, { useState, useEffect } from "react";
import { useLocation  } from 'react-router-dom';
import Loader from "../Loader/Loader";

const SearchChamerlean = () => {
  const [loader, setLoader] = useState(true);
  const [provider, setProvider] = useState('all');

  useEffect(() => {
    const fetchedUrl = window.location.href;

    const params = new URL(fetchedUrl).searchParams;
    const providerName = params.get('provider');

    if(providerName)
    {
      setProvider(providerName);
    }

    const script = document.createElement("script");
    script.src = "https://static.myfinance.com/embed/inlineMedia_core.js";
    script.async = true;
    script.onload = () => {
      window.initializeInline({ loadInlineCss: true });
    };
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    const handlePageLoad = () => {
      setLoader(false);
    };

    if (document.readyState === "complete") {
      handlePageLoad();
    } else {
      window.addEventListener("load", handlePageLoad);
    }

    return () => {
      window.removeEventListener("load", handlePageLoad);
    };
  }, []);

  return (
    <div className="banner_content">
        {
          loader && <Loader />
        }
        <div className="custom-container">
        {
          (provider === 'all' || provider === 'spectrum') && (
            <div class="myFinance-widget" data-ad-id="5101afe0-ead9-4e5e-b04a-dced655f805b" data-campaign="chameleon-comlink-osb-spectrum"></div>
          )
        }
        
        {
          (provider === 'all' ||  provider == 'frontier') && (  
          
          <div class="myFinance-widget" data-ad-id="e4fcd41f-5310-4104-b2ce-4e73b05c00af" data-campaign="chameleon-quantumax-osb-frontier"></div>
        )}
        {
          (provider === 'all' ||  provider == 'verizon') &&  (

            <div class="myFinance-widget" data-ad-id="11681fe7-bfae-4955-beba-41837a3e15ee" data-campaign="chameleon-comlink-osb-verizon"></div>
        )}
        {
            (provider === 'all' ||  provider == 'att') && (  

              <div class="myFinance-widget" data-ad-id="cff545e0-3ab9-46b4-b1e2-de5a7453afda" data-campaign="chameleon-comlink-osb-att"></div>
        )}
        {
          (provider === 'all' ||  provider == 'centurylink') && (  
          
            <div class="myFinance-widget" data-ad-id="cc6a803c-9cc2-45ee-ba43-fe74cb7f9f7f" data-campaign="chameleon-comlink-osb-centurylink"></div>
        )}
        {
          (provider === 'all' ||  provider == 'cox') && (  
            <div class="myFinance-widget" data-ad-id="5c23b65e-07e6-4418-83b8-e4bf98eb7aa4" data-campaign="chameleon-comlink-osb-cox"></div>
        )}
        {
          (provider === 'all' ||  provider == 'xfinity') && (  

            <div class="myFinance-widget" data-ad-id="74efdaeb-e4fe-4666-95e1-2ee7b22ff806" data-campaign="chameleon-comlink-osb-xfinity"></div>
        )}
        {
          (provider === 'all' ||  provider == 'tmobile') && (  

            <div class="myFinance-widget" data-ad-id="c9c489b2-4e2c-4c57-ab33-fe82e6551dee" data-campaign="chameleon-comlink-osb-tmobile"></div>
        )}
        </div>
    </div>
  );
};

export default SearchChamerlean;
